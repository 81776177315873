var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShowPage ? _c('div', {
    staticClass: "login x"
  }, [_vm.noChrome ? _c('div', {
    staticClass: "chrome"
  }, [_vm._v(" 为了良好的操作体验，请使用"), _c('a', {
    staticStyle: {
      "color": "blue"
    },
    attrs: {
      "href": _vm.chromeHref
    }
  }, [_vm._v("谷歌浏览器(点击直接下载)")]), _vm._v("（注：其他浏览器可能存在兼容性问题） ")]) : _vm._e(), _c('img', {
    staticClass: "height",
    attrs: {
      "src": require("../../assets/bg_pic.png")
    }
  }), _c('div', {
    staticClass: "flex1"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pwdLogin,
      expression: "pwdLogin"
    }],
    staticClass: "content"
  }, [_c('img', {
    staticClass: "login-icon",
    attrs: {
      "src": require("../../assets/qrlogin.png")
    },
    on: {
      "click": function click($event) {
        return _vm.handleClick(false);
      }
    }
  }), _c('div', [_c('p', {
    staticClass: "fs22 bold"
  }, [_vm._v("登录房易宝")]), _vm._m(0), _c('el-form', {
    ref: "loginForm",
    staticClass: "login-form",
    attrs: {
      "model": _vm.loginForm,
      "autocomplete": "on",
      "label-position": "left"
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "cellphone",
      "rules": [_vm.rules.required(), _vm.rules.tel]
    }
  }, [_c('el-input', {
    attrs: {
      "size": "large",
      "placeholder": "请输入手机号",
      "type": "text",
      "maxlength": "11"
    },
    model: {
      value: _vm.loginForm.cellphone,
      callback: function callback($$v) {
        _vm.$set(_vm.loginForm, "cellphone", $$v);
      },
      expression: "loginForm.cellphone"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "prop": "code",
      "rules": [_vm.rules.required(), _vm.rules.d6]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入短信验证码",
      "type": "text",
      "maxlength": "6"
    },
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.login.apply(null, arguments);
      }
    },
    model: {
      value: _vm.loginForm.code,
      callback: function callback($$v) {
        _vm.$set(_vm.loginForm, "code", $$v);
      },
      expression: "loginForm.code"
    }
  }, [_c('div', {
    staticClass: "verification",
    attrs: {
      "slot": "suffix"
    },
    slot: "suffix"
  }, [_c('i'), _c('p', {
    class: {
      blue: !_vm.smsing
    },
    on: {
      "click": _vm.sms
    }
  }, [_vm._v(" " + _vm._s(_vm.vText) + " ")])])])], 1)], 1), _c('el-button', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "loading": _vm.toggleLoading,
      "type": "primary",
      "disabled": !_vm.formFill
    },
    on: {
      "click": _vm.login
    }
  }, [_vm._v(" 登录 ")]), _c('p', {
    staticClass: "color9",
    staticStyle: {
      "line-height": "1.5rem",
      "margin-top": "10px"
    }
  }, [_vm._v(" 未开通房易宝WEB端管理后台用户，请联系你所在企业管理员 或者联系房易宝市场人员，开通企业账号! ")])], 1)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.pwdLogin,
      expression: "!pwdLogin"
    }],
    staticClass: "content"
  }, [_c('img', {
    staticClass: "login-icon",
    attrs: {
      "src": require("../../assets/pwdlogin.png")
    },
    on: {
      "click": function click($event) {
        return _vm.handleClick(true);
      }
    }
  }), _vm._m(1)])])]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "fs16 color6",
    staticStyle: {
      "margin": "16px 0 48px"
    }
  }, [_vm._v(" 登录即代表你已阅读并同意"), _c('span', {
    staticClass: "blue"
  }, [_vm._v("服务协议")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', {
    staticClass: "fs22 bold"
  }, [_vm._v("微信扫描登录")]), _c('p', {
    staticClass: "fs16 color6",
    staticStyle: {
      "margin": "16px 0 48px"
    }
  }, [_vm._v(" 请使用微信“扫一扫”扫描二维码 ")]), _c('div', {
    staticClass: "x1",
    staticStyle: {
      "position": "relative",
      "top": "-56px"
    }
  }, [_c('div', {
    staticClass: "qr-code-wrap"
  }, [_c('div', {
    attrs: {
      "id": "login_container"
    }
  })])]), _c('p', {
    staticClass: "color9",
    staticStyle: {
      "line-height": "1.5rem",
      "position": "relative",
      "top": "-120px"
    }
  }, [_vm._v(" 未开通房易宝WEB端管理后台用户，请联系你所在企业管理员 或者联系房易宝市场人员，开通企业账号! ")])]);

}]

export { render, staticRenderFns }