<template>
  <div class="login x" v-if="isShowPage">
    <div class="chrome" v-if="noChrome">
      为了良好的操作体验，请使用<a style="color: blue" :href="chromeHref"
        >谷歌浏览器(点击直接下载)</a
      >（注：其他浏览器可能存在兼容性问题）
    </div>
    <img src="../../assets/bg_pic.png" class="height" />
    <div class="flex1">
      <div class="content" v-show="pwdLogin">
        <img
          src="../../assets/qrlogin.png"
          class="login-icon"
          @click="handleClick(false)"
        />
        <div>
          <p class="fs22 bold">登录房易宝</p>
          <p style="margin:16px 0 48px" class="fs16 color6">
            登录即代表你已阅读并同意<span class="blue">服务协议</span>
          </p>
          <el-form
            class="login-form"
            ref="loginForm"
            :model="loginForm"
            autocomplete="on"
            label-position="left"
          >
            <el-form-item
              prop="cellphone"
              :rules="[rules.required(), rules.tel]"
            >
              <el-input
                size="large"
                v-model="loginForm.cellphone"
                placeholder="请输入手机号"
                type="text"
                maxlength="11"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="code" :rules="[rules.required(), rules.d6]">
              <el-input
                v-model="loginForm.code"
                placeholder="请输入短信验证码"
                type="text"
                maxlength="6"
                @keyup.enter.native="login"
              >
                <div slot="suffix" class="verification">
                  <i></i>
                  <p :class="{ blue: !smsing }" @click="sms">
                    {{ vText }}
                  </p>
                </div>
              </el-input>
            </el-form-item>
          </el-form>
          <el-button
            :loading="toggleLoading"
            style="width:100%"
            type="primary"
            :disabled="!formFill"
            @click="login"
          >
            登录
          </el-button>
          <p class="color9" style="line-height:1.5rem;margin-top:10px;">
            未开通房易宝WEB端管理后台用户，请联系你所在企业管理员
            或者联系房易宝市场人员，开通企业账号!
          </p>
        </div>
      </div>
      <div class="content" v-show="!pwdLogin">
        <img
          src="../../assets/pwdlogin.png"
          class="login-icon"
          @click="handleClick(true)"
        />
        <div>
          <p class="fs22 bold">微信扫描登录</p>
          <p style="margin:16px 0 48px" class="fs16 color6">
            请使用微信“扫一扫”扫描二维码
          </p>
          <div class="x1" style="position:relative;top: -56px">
            <div class="qr-code-wrap">
              <div id="login_container"></div>
            </div>
          </div>
          <p
            class="color9"
            style="line-height:1.5rem;position:relative;top: -120px"
          >
            未开通房易宝WEB端管理后台用户，请联系你所在企业管理员
            或者联系房易宝市场人员，开通企业账号!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CODE,
  getVerifyCode,
  cellphoneLogin,
  weixinLogin,
  getUserInfo
} from "@/api/login";
import rules from "@/utils/rules";
import { getEstateInfo, getPlatformInfo } from "@/utils/getCurrentInfo";
import qs from "querystring";

const second = 60;
export default {
  data() {
    return {
      isShowPage: true, //显示页面
      rules,
      pwdLogin: true,
      loginForm: {
        cellphone: "",
        code: ""
      },
      smsing: false,
      vText: "获取验证码",
      timer: null,
      time: second,
      disabledBtn: true,
      perfectInformation: false,
      dynamicLogin: false,
      toggleLoading: false,
      // 临时楼盘活动手机账号对应的管理页面
      tempActivityPathMap: {
        "18109077842": `/temp/activity/tianfuyinghui`,
        // "18109077842": `/temp/activity/yuanqili`,
        "18140000163": `/temp/activity/tianfuyinghui`,
        "18180236152": `/temp/activity/ersanli`
      }
    };
  },
  computed: {
    noChrome() {
      let flag = navigator.userAgent.indexOf("Chrome") == -1;
      return flag;
    },
    chromeHref() {
      let isMac = /macintosh|mac os x/i.test(navigator.userAgent);
      let href =
        "https://fangyibao-res.oss-cn-hangzhou.aliyuncs.com/app_download/ChromeSetup.rar";
      if (isMac) {
        href =
          "https://fangyibao-res.oss-cn-hangzhou.aliyuncs.com/app_download/googlechrome.dmg";
      }
      return href;
    },
    // 表单填写完整
    formFill() {
      const { cellphone, code } = this.loginForm;
      const validPhone = /^1\d{10}$/.test(cellphone);
      return validPhone && /\d{6}/.test(code);
    }
  },
  created() {
    this.wxLogin();
    this.pcnLogin();
    this.handleMicroAppJump();
  },
  methods: {
    sms() {
      this.$refs.loginForm.validateField("cellphone", async valid => {
        const errMsgs = ["目前只支持中国大陆的手机号码", "不能为空"];
        if (errMsgs.indexOf(valid) === -1) {
          if (this.smsing) return;
          const reset = () => {
            this.vText = "重新获取";
            clearInterval(this.timer);
            this.time = second;
            this.smsing = false;
          };
          this.smsing = true;
          this.vText = `60s`;
          this.timer = setInterval(() => {
            this.time--;
            this.vText = `${this.time}s`;
            if (this.time < 0) reset();
          }, 1000);
          const res = await getVerifyCode({
            cellphone: this.loginForm.cellphone
          });
          if (!res) reset();
        }
      });
    },
    async wxLogin() {
      //微信登录
      const query = location.href.split("?")[1];
      if (query) {
        let obj = qs.parse(query);
        let code = obj && obj.code;
        if (!code) return;
        const res = await weixinLogin({ code });
        if (res) {
          this.handleLoginRes(res);
        }
      }
    },
    // 获取用户信息
    async getUserInfo(token) {
      const res = await getUserInfo();
      if (res) {
        this.$store.commit("SET_USER_INFO", { ...res, token });
        this.$store.commit("SET_PERMISSION_MENUS", {
          baseMenus: res.menuTree
        });
      }
    },
    //房猫P+跳转过来登录
    async pcnLogin() {
      const { userId, token, toPath } = this.$route.query;
      // 没有包含用户数据和 跳转页面路径 就不往下执行
      if (!userId || !token || !toPath) return;
      this.isShowPage = false;
      this.$store.commit("SET_USER_INFO", { userId, token });
      await this.getUserInfo(token);
      // //房猫跳转过来，就拥有楼盘管理权限，这里手动赋权
      const path = decodeURIComponent(toPath);
      const pathQuery = path.split("?")[1];
      // 方法内判断如果路径携带了平台id或者楼盘id 则预先请求相应数据，为后续页面做准备
      getPlatformInfo(pathQuery);
      getEstateInfo(pathQuery);
      sessionStorage.setItem("fromPCN", 1);
      this.$router.replace(path);
    },
    // 处理微应用路径跳转
    handleMicroAppJump() {
      const { toPath } = qs.parse(location.search.replace(/^\?/, ""));
      if (!toPath) return;
      const path = decodeURIComponent(toPath);
      const query = path.split("?")[1];
      this.isShowPage = false;
      if (query) {
        getPlatformInfo(query);
        getEstateInfo(query);
      }
      if (path) {
        this.$router.replace(path);
      }
    },
    // 登录
    login() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          const { cellphone } = this.loginForm;
          this.toggleLoading = true;
          //手机动态登录
          const data = await cellphoneLogin(this.loginForm);
          /*********************临时活动start***/

          if (
            data &&
            Object.keys(this.tempActivityPathMap).includes(cellphone)
          ) {
            const activityPath = this.tempActivityPathMap[cellphone];
            this.$store.commit("SET_USER_INFO", { activityPath, ...data });
            this.$router.push(activityPath);
            /*********************临时活动end***/
          } else {
            this.handleLoginRes(data);
          }
          this.toggleLoading = false;
        } else {
          return false;
        }
      });
    },
    handleLoginRes(data) {
      //处理登录返回数据
      if (data) {
        if (!data.menuTree || !data.menuTree.length) {
          this.$showError("暂未访问权限");
          return;
        }
        this.$store.commit("SET_USER_INFO", data);
        this.$store.commit("SET_PERMISSION_MENUS", {
          baseMenus: data.menuTree
        });
        const menu = data.menuTree[0].children
          ? data.menuTree[0].children[0]
          : data.menuTree[0];
        this.$router.push(menu.menuPath);
      }
    },
    handleClick(flag) {
      //      创建案二维码登录
      this.pwdLogin = flag;

      if (!flag) {
        let appid = CODE.appId;
        let redirect_uri = CODE.redirect_uri;
        // eslint-disable-next-line no-undef
        new WxLogin({
          self_redirect: false,
          id: "login_container",
          appid: appid,
          scope: "snsapi_login",
          redirect_uri: redirect_uri,
          state: "anchang",
          style: "white",
          href: CODE.cssHref
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.chrome {
  height: 50px;
  line-height: 50px;
  background: #fcf6ed;
  color: #dca450;
  text-align: center;
  position: fixed;
  width: 100vw;
  z-index: 9999;
}
.qr-code-wrap {
  margin: 45px 0 80px 0;
  display: block;
  width: 300px;
  height: 300px;
  overflow: hidden;
  #login_container {
    margin-top: -47px;
  }
}
.login {
  height: 100vh;
  overflow: hidden;
  .content {
    width: 520px;
    margin: 15vh auto 0;
    padding: 75px 50px 0;
    position: relative;
    .login-icon {
      position: absolute;
      right: 0;
      top: 0;
      width: 75px;
      height: 75px;
      cursor: pointer;
    }
    .login-form {
      .verification {
        height: 20px;
        width: 90px;
        margin: 10px 0;
        display: flex;
        & > i {
          display: inline-block;
          width: 1px;
          height: 20px;
          background: #cccccc;
        }
        & > p {
          flex: 1;
          height: 20px;
          line-height: 20px;
          cursor: not-allowed;
          color: #999;
          &.blue {
            color: #409eff;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
